import React from 'react'
import './project.css';
import Clip from "./video.mp4";

function project() {
    return (
        <div id="home">
            <div className='projectvideo'>
                <video src={Clip} loop muted autoPlay></video>
                
                {/* <div className='projectparagraph'>
                    <div className='studioname' >
                        <h1 data-aos="fade-up">SMART HOMES</h1>
                       
                    </div>
                
                    <h3 style={{textAlign:"center"}}>Smart, Comfortable & Convenient Life</h3>
                    <br/>
                    <h3 style={{textAlign:"center"}}> Control your home from 
                          
                          Tab or Mobile or Remote 
                          An Innovative home automation at affordable price</h3>
                  
                
                </div> */}
            </div>
        </div>
    )
}

export default project