import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Row, Col,Card } from 'antd'
import './About.css'
import about1 from '../../../assets/image/smart-home-future.jpg'
export default function AboutMain() {
  return (
    <Wrapper id="about">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font30 extraBold">About APSH</h1>
            {/* <p className="font13">
              Join us,we are interconnecting transporters with companies to make your daily hiring and sourcing
              <br />
              Save time and money,No Commisson,No broker,No agent,JUST direct interaction.
            </p> */}
          </HeaderInfo>

          <Row>
            <Col className="about_first_row" style={{ paddingRight: "10px" }}  data-aos="fade-up">
              <p className="font15">
              AP Smart Home (APSH) offers packages depending on the requirements of houses or features asked by the customer. We design home automation packages suits customer requirements i.e. temperature control, humidity control, water level management, light and fan control, curtain control, remote locking system, air conditioning control.
              </p>
              <br />
              <p className="font15">
              Our aim is to make home automation easier and more affordable than ever proposed by our competitors. We continue to add our innovative ideas to provide more comfort to our customers. Our upcoming voice control command provides the best comfort and convenience for users. Our upcoming advanced controller & software will facilitate our clients with some excellent unique features. For example, when coming home after dark, you can just command<span style={{ fontWeight: "550",color:"black" }}>TURN THE LIGHT ON</span> or you leave the house you call <span style={{ fontWeight: "550",color:"black"  }}>SWITCH OFF ALL LIGHTS</span> - it's done.
              </p>
            </Col>
            <Col className="about_first_row"  data-aos="fade-up">
              <img src={about1} />
            </Col>
          </Row>
      <Row className="about_second_row">
      <Col className="about_second_row_col">
        <Card 
         title={<span style={{color:"white"}}>AP Smart Homes provides</span>}
  
         data-aos="fade-up"
         className="about_card"
        >
   
          <ul className="list-style-type">
          <li><b>Cutomer oriented design</b></li>
          <li><b>Cost effective and economical</b></li>
          <li><b>Make your home smart and life easier</b></li>
          <li><b>Save energy and time</b></li>
          <li><b>Team available for service</b></li>
          <li><b>Android app. Offre ease</b></li>
          </ul>
        </Card>
        </Col>
        <Col className="about_second_row_col">
        <Card 
          
            data-aos="fade-up"
            className="about_card"
            title={<span style={{color:"white"}}>Smart Home Security System</span>}
        >
        <ul className="list-style-type">
          <li><b>Security locks of your door by ipad or Smart Phone</b></li>
          <li><b>Access control , CCTV control</b></li>
          <li><b>Fire gas leak alarm</b></li>
          <li><b>Keep an eye on your home, even if you away</b></li>
          <li><b>One touch control</b></li>
          </ul>
          
        </Card>
        </Col>
        <Col className="about_second_row_col">
        <Card  
        title={<span style={{color:"white"}}>Smart Homes Key Fearures</span>}  
        data-aos="fade-up"   className="about_card">
        <ul className="list-style-type">
          <li><b>Control of lighting</b></li>
          <li><b>Automated control of curtain</b></li>
          <li><b>Everyday energy monitoring on Tab & Mobile</b></li>
          <li><b>Humidity and temperature control</b></li>
          <li><b>Water management and control</b></li>
          </ul>
        </Card>
        </Col>
      
      </Row>
        </div>
      </div>

    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

