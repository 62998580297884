import React from "react";
import styled from "styled-components";
import Gellary from "./Gellary";

export default function Blog() {
  return (

        <Wrapper id="blog"  data-aos="fade-up">
        <div className="whiteBg" style={{ padding: "60px 0" }}>
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Work Gallery</h1>
              <p className="font13">
              We have held Photography Campaigns and various workshops
                on Film-making and Photography
       
              </p>
            </HeaderInfo>
         
          </div>
         
        </div>
        <Gellary />
      </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;

`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;