import React from "react";
// Sections

// import TopNavbar from "../components/LandingPage/Nav/TopNavbar";
import TopNavbar from '../LandingPage/Nav/TopNavbar'
import Header from "../LandingPage/Sections/Header";
import Services from "../LandingPage/Sections/Services";
import Projects from "../LandingPage/Sections/Projects";
import Blog from "../LandingPage/Sections/Blog";
// import Pricing from "../LandingPage/Sections/Pricing";
import Contact from "../LandingPage/Sections/Contact";
import Project from "./project";
import AboutMain from "./About/AboutMain";
// import Reviews from "./Reviews"
import Reviews from './Sections/Reviews'
import Footer from "./Footer";
export default function Landing() {
  return (
    <>
      <TopNavbar />

      <Project/>
      <AboutMain/>
      <Services />
      <br/>
      {/* <Projects /> */}
      <Blog />
      <Reviews/>
      <Contact />
<Footer/>
    </>
  );
}


