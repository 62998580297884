import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import about from '../../../assets/image/about.jpg';
import area from '../../../assets/image/area.png';
import serviceback from '../../../assets/image/serviceback.jpg';
import ai from '../../../assets/image/ai.jpeg';
import background from '../../../assets/image/background.jpeg';
import back from '../../../assets/image/backgroundmain.jpg';
import camra from '../../../assets/image/camra.jpeg';
import caset from '../../../assets/image/caset.jpeg';
import './Slider.css';
import { Card ,Rate} from "antd";
import styled from "styled-components";
export default class Responsive extends Component {
  render() {
    var settings = {
      autoplay:true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const reviews =[
      {
        "id":8,
        "name":"Paresh Kalla",
        "stars":5,
        "message":"Very impressed with AP Smart Home's customer service. They were responsive to all my inquiries and made sure I was satisfied with the installation. My home feels safer and more connected now!"
      },
      {
        "id":9,
        "name":"R.A Yadav",
        "stars":4,
        "message":"AP Smart Home turned my house into a smart home dream! Their team was professional, courteous, and made the entire process hassle-free. I love being able to automate tasks and control everything remotely. Highly recommended!"
      },
      {
        "id":10,
        "name":"Satish Agrawal",
        "stars":4,
        "message":"I can't believe how much easier my life has become since installing a digital home system from AP Smart Home. From setting schedules for my appliances to enhancing home security, it's all been seamless. Great job, team!"
      },
      {
        "id":1,
        "name":"Tejas Shah",
        "stars":5,
        "message":"Excellent performance, too good. Made in India, made in Vadodara. Proud to see such a good manufacturer. Automation works at its best. App is smooth. Also their decides can be connected to Alexa and Google home."
      },
      {
        "id":2,
        "name":"Rushal Dudhat",
        "stars":4,
        "message":"Reliable, easy to use and install home automation products. I saw their live demo and was very impressed with the product"
      },
      {
        "id":3,
        "name":"Hardik Jagana",
        "stars":3,
        "message":"Easy installation, no need rewiring... I used this product last 1 year ....no error, nothing happened uptill now thanks to smart h automation for easier way to my life"
      },
      {
        "id":4,
        "name":"Vikas Yadu",
        "stars":5,
        "message":"Thanks to AP Smart Home, my home feels like something out of the future! The installation process was smooth, and their team did a great job explaining everything. I've already recommended them to friends and family"
      },
      {
        "id":5,
        "name":"Harish Sen",
        "stars":5,
        "message":"Incredible service from AP Smart Home! They installed a complete smart home system for me, and I couldn't be happier. The team was professional, knowledgeable, and efficient. Highly recommend"
      },
          {
        "id":6,
        "name":"Yuvraj Sahu",
        "stars":4,
        "message":"AP Smart Home helped me upgrade my home with the latest technology. The installation process was smooth, and they took the time to explain how everything works. Great service overall"
      },
      {
        "id":7,
        "name":"Satyendra Paswan",
        "stars":4,
        "message":"I'm amazed by the convenience my new digital home system brings. Thanks to AP Smart Home, I can control everything from my lights to my security system with just a tap on my phone. Top-notch service"
      },
   
    ]

    // const reviews=[
    //   {
    //     "name": "John Smith",
    //     "stars": 5,
    //     "message": "Incredible service from Digital Home! They installed a complete smart home system for me, and I couldn't be happier. The team was professional, knowledgeable, and efficient. Highly recommend!"
    //   },
    //   {
    //     "name": "Jane Doe",
    //     "stars": 4,
    //     "message": "Very impressed with Digital Home's customer service. They were responsive to all my inquiries and made sure I was satisfied with the installation. My home feels safer and more connected now!"
    //   },
    //   {
    //     "name": "Alex Johnson",
    //     "stars": 5,
    //     "message": "I'm amazed by the convenience my new digital home system brings. Thanks to Digital Home, I can control everything from my lights to my security system with just a tap on my phone. Top-notch service!"
    //   },
    //   {
    //     "name": "Emily Brown",
    //     "stars": 4,
    //     "message": "Overall, I'm pleased with the digital home setup provided by Digital Home. The system works as expected, and their team did a good job with the installation. Minor issues were promptly addressed. Solid service!"
    //   },
    //   {
    //     "name": "Michael Wilson",
    //     "stars": 5,
    //     "message": "Exceptional service from Digital Home! They transformed my living space into a smart, connected environment. The team was professional, friendly, and made sure I understood how to use all the features. Couldn't be happier!"
    //   },
    //   {
    //     "name": "Sarah Martinez",
    //     "stars": 4,
    //     "message": "Digital Home turned my house into a smart home dream! Their team was professional, courteous, and made the entire process hassle-free. I love being able to automate tasks and control everything remotely. Highly recommended!"
    //   },
    //   {
    //     "name": "David Thompson",
    //     "stars": 5,
    //     "message": "I can't believe how much easier my life has become since installing a digital home system from Digital Home. From setting schedules for my appliances to enhancing home security, it's all been seamless. Great job, team!"
    //   },
    //   {
    //     "name": "Emma Clark",
    //     "stars": 4,
    //     "message": "Digital Home helped me upgrade my home with the latest technology. The installation process was smooth, and they took the time to explain how everything works. Great service overall!"
    //   },
    //   {
    //     "name": "Christopher Lee",
    //     "stars": 5,
    //     "message": "Thanks to Digital Home, my home feels like something out of the future! The installation process was smooth, and their team did a great job explaining everything. I've already recommended them to friends and family."
    //   },
    //   {
    //     "name": "Olivia Taylor",
    //     "stars": 4,
    //     "message": "Digital Home delivered on their promise to modernize my home with smart technology. The installation process was efficient, and their technicians were knowledgeable. A few small hiccups along the way, but nothing major. Happy with the outcome!"
    //   }
    // ]
    
    return (
      <Wrapper id="reviews"  data-aos="fade-up">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Customer Reviews</h1>
            <p className="font13">
            A customer review is an evaluation of a product or service made by someone who has purchased and used, or had experience with, a product or service .
     
            </p>
          </HeaderInfo>
       
        </div>
       
      </div>

      <div style={{paddingTop:'20px'}} >
        
        <div style={{width:'90%',margin:'auto'}}>
        <Slider {...settings} >
          {reviews.map((i)=>(
          <div>
            <Card className="card">
            <Rate allowHalf defaultValue={i.stars} />
            <h1><b>{i.name}</b></h1>
            <h3>{i.message}</h3>
            </Card>
          </div>
       
         ))}
        </Slider>
      </div>
      </div>
 
    </Wrapper>
    );
  }
}
const Wrapper = styled.section`
  width: 100%;

`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

