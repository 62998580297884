import React, { useEffect, useState } from 'react';
import './Gellary.css';
// import vision from '../../../assets/image/vision.jpeg';
// import boy from '../../../assets/image/boy.jpeg';
// import room from '../../../assets/image/room.jpeg';
// import partner from '../../../assets/image/partner.jpeg';
// import studio from '../../../assets/image/1.png';
// import aim from '../../../assets/image/2.png';
// import images from '../../../assets/image/images.jpeg';
// import caset from '../../../assets/image/3.jpg';
import img1 from '../../../assets/image/1.jpg'
import img2 from '../../../assets/image/2.jpg'
import img3 from '../../../assets/image/3.jpg'
import img4 from '../../../assets/image/4.jpg'
import img5 from '../../../assets/image/5.jpg'
import img6 from '../../../assets/image/6.jpg'
import img7 from '../../../assets/image/7.jpg'
import img8 from '../../../assets/image/8.jpg'
import { Row, Col } from 'antd';
import AOS from 'aos';
import styled from "styled-components";
import 'aos/dist/aos.css';
function Gellary() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            easing: 'ease-in-out', // Animation easing
        });
    }, []);
    const [buttonId, setButtonId] = useState(1)


    const AllImages = [
        {
            img: img1,
            type: 'events'
        },
        {
            img: img2,
            type: 'events'
        },
        {
            img: img3,
            type: 'music'
        },
        {
            img: img4,
            type: 'wedding'
        },
        {
            img: img5,
            type: 'events'
        },
        {
            img: img6,
            type: 'events'
        },
        {
            img: img7,
            type: 'music'
        },
        {
            img: img8,
            type: 'wedding'
        },

    ]

    const [imagesData, setimagesData] = useState(AllImages)

    return (
        <div className='gellary_container_main' data-aos="flip-up">
       
            <>
                <Row>
                </Row>
                <Row className='gellary_container_goal'>
                    {imagesData.map((e) => (
                        <Col className='gellary_container_goal_col'>
                            <div >
                                <img className='gellary_img' data-aos="fade-up" src={e.img}></img>
                            </div>
                        </Col>
                    ))}
                </Row>
                {/* <div>
                    <button className='see_more_button' data-aos="flip-up"

                    >See More</button>
                </div>
                <br />  <br /> */}
            </>
        </div>
    )
}

export default Gellary;


const HeaderInfo = styled.div`
            @media (max-width: 860px) {
              text-align: center;
            }
          `;