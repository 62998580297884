import React from 'react';
// import 'antd/dist/antd.css';
import './Product.css'
import { Collapse, Row, Col, Card } from 'antd';
// import apsh_touch from '../../../assets/image/APSH TOUCH 2.jpg'
// import apsh_touch_PLUS from '../../../assets/image/APSH TOUCH PLUS.jpg'
// import apsh_touch_mini from '../../../assets/image/touch_mini.jpg'

import apsh_touch from '../../../assets/image/APSH Touch.jpg'
import apsh_touch_PLUS from '../../../assets/image/APSH Touch Plus.jpg'
import apsh_touch_mini from '../../../assets/image/APSH Touch Mini.PNG'

const { Panel } = Collapse;
const Product = () => {
    const onChange = (key) => {
        console.log(key);
    };
    return (
        <div >
            <br />
            <Collapse onChange={onChange} style={{ marginTop: "20px" }} defaultActiveKey={['1']} >
                <Panel header={<span style={{ color: "white" }}>APSH TOUCH PLUS</span>} key="1" style={{ backgroundColor: "black" }}>
                    <Row>
                        <Col className='product_col' data-aos="fade-up">
                            <img src={apsh_touch_PLUS} />
                        </Col>
                        <Col className='product_col' data-aos="fade-up">
                            <Card
                                title={<span style={{ color: "white" }}>APSH TOUCH PLUS</span>}

                                className="about_card"
                            >

                                <ul className="list-style-type">
                                    <li><b>Enhanced Smart home Controller</b></li>
                                    <li><b>7 inch Touch Display</b></li>
                                    <li><b>4-8 Channels</b></li>
                                    <li><b>Living/ Drawing room control
                                    </b></li>
                                    <li><b>Bedroom Control
                                    </b></li>
                                    <li><b>Curtain Control
                                    </b></li>
                                    <li><b>AC Contro
                                    </b></li>
                                    <li><b>Light & Fan control
                                    </b></li>
                                    <li><b>Integration Of Home Appliances
                                    </b></li>
                                    <li><b>Voice Over Control
                                    </b></li>
                                    <li><b>Centralized Home Automation (Custom Mode)
                                    </b></li>
                                    {/* <li><b>Voice Control
                                    </b></li>
                                    <li><b>Compact Design</b></li>
                                    <li><b>Dimming Control For Fan & Lights</b></li>
                                    <li><b>Gesture Control
                                    </b></li>

                                    <li><b>Smart light Control system</b></li>
                                    <li><b>Wall Mounted
                                    </b></li>
                                    <li><b>Inbuilt memory
                                    </b></li> */}
                                </ul>
                            </Card>
                        </Col>
                    </Row>
                </Panel>

            </Collapse>
            <br/>
            <Collapse onChange={onChange} >
                <Panel header={<span style={{ color: "white" }}>APSH TOUCH</span>} key="1" style={{ backgroundColor: "black" }}>

                    <Row>
                        <Col className='product_col' data-aos="fade-up">
                            <img src={apsh_touch} />
                        </Col>
                        <Col className='product_col' data-aos="fade-up">
                            <Card
                                title={<span style={{ color: "white" }}>APSH TOUCH</span>}


                                className="about_card"
                            >

                                <ul className="list-style-type">
                                    <li><b>Smart home Controller</b></li>
                                    <li><b>5 inch Touch Display</b></li>
                                    <li><b>4-8 Channels</b></li>
                                    <li><b>Living/ Drawing room control</b></li>
                                    <li><b>Bedroom Control</b></li>
                                    <li><b>Curtain Contro</b></li>
                                    <li><b>AC Control</b></li>
                                    <li><b>Light & Fan control</b></li>
                                 
                                
                                    <li><b>Integration Of Home Appliances
                                    </b></li>
                                    <li><b>Voice Over Control
                                    </b></li>
                                    <li><b>Centralized Home Automation (Custom Mode)
                                    </b></li>
                                    {/* <li><b>5 inch Touch Display</b></li>
                                    <li><b>Voice Control
                                    </b></li>
                                    <li><b>Compact Design</b></li>
                                    <li><b>Dimming Control For Fan & Lights</b></li>
                                    <li><b>Gesture Control</b></li>
                                    <li><b>4-8 Channels</b></li>
                                    <li><b>Smart light Control system</b></li>
                                    <li><b>Wall Mounted
                                    </b></li>
                                    <li><b>Inbuilt memory
                                    </b>
                                    </li> */}
                                </ul>
                            </Card>
                        </Col>
                    </Row>
                </Panel>

            </Collapse>
         
            <Collapse onChange={onChange} style={{ marginTop: "20px" }}>
                <Panel header={<span style={{ color: "white" }}>APSH TOUCH MINI</span>} key="1" style={{ backgroundColor: "black" }}>
                    <Row>
                        <Col className='product_col' data-aos="fade-up">
                            <img src={apsh_touch_mini} />
                        </Col>
                        <Col className='product_col' data-aos="fade-up">
                            <Card
                                title={<span style={{ color: "white" }}>APSH TOUCH MINI</span>}


                                className="about_card"
                            >

                                <ul className="list-style-type">
                                    <li><b>Compact Smart home Controller</b></li>
                                    <li><b>3.2 inch Touch Display</b></li>
                                    <li><b>2-3 Channels</b></li>
                                    <li><b>Bedroom Automation</b></li>
                                    <li><b>Light & Fan control</b></li>
                                
                                    {/* <li><b>5 inch Touch Display</b></li>
                                    <li><b>Voice Control
                                    </b></li>
                                    <li><b>Compact Design</b></li>
                                    <li><b>Dimming Control For Fan & Lights</b></li>
                                    <li><b>Gesture Control</b></li>
                                    <li><b>4-8 Channels</b></li>
                                    <li><b>Smart light Control system</b></li>
                                    <li><b>Wall Mounted
                                    </b></li>
                                    <li><b>Inbuilt memory
                                    </b></li> */}
                                </ul>
                            </Card>
                        </Col>
                    </Row>
                </Panel>

            </Collapse>

        </div>
    );
};

export default Product;