import styled from "styled-components";
// Assets
import { Link } from "react-router-dom"
import { Card } from "antd";
import React, { useRef, useState } from 'react';
import { AiFillTwitterCircle } from "react-icons/ai";
import emailjs from '@emailjs/browser';
import { Modal, Button } from "antd";
import {
  BsApple,
  BsFacebook,
  BsInstagram,
  BsYoutube,
  BsTelegram,
  BsLinkedin,
} from "react-icons/bs";
export default function Contact() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('')
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    // service_hwyl2tn
    emailjs.sendForm('service_xg808uq', 'template_5qxmx3s', form.current, 'WU9ghSYWmRGLA3Quo')
      .then((result) => {
        if (result.text === 'OK') {
          setIsModalOpen(true)
          form.current.reset();
        }
      }, (error) => {
        console.log(error.text);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Wrapper id="contact" data-aos="fade-up">
      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        width={400}
        style={{ textAlign: "center" }}
      >
        <h1 >Thank You {name} From AP Smart Homes</h1>
        <h1 >We Will Contact You Very Soon</h1>
        <Button onClick={handleCancel}
          style={{
            marginTop: "10px",
            color: "white",
            backgroundColor: "black"
          }}
        >Ok</Button>

      </Modal>
      <div className="container">
        <HeaderInfo>
          <h1 className="font40 extraBold">GET IN TOUCH</h1>
          <p className="font13">

            For business inquiries, email us at apsmarthome0gmail.com or call 93025-22138. We're eager to discuss potential Service.

          </p>
        </HeaderInfo>


      </div>

      <div >
        <div className="container">
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form ref={form} onSubmit={sendEmail}>
                <label className="font13">Full Name:</label>
                <input type="text" id="fname" name="user_name" className="font20 extraBold" required
                  onChange={(e) => setName(e.target.value)}
                />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="user_email" className="font20 extraBold" required />
                <label className="font13">Mobile No:</label>
                <input type="text" id="email" name="mobileNumber" className="font20 extraBold" required />
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold" required />
                <label className="font13">Message</label>
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" required />

                <SumbitWrapper className="flex">
                  {/* <ButtonInput type="submit" value="" className="pointer animate radius8" style={{ maxWidth: "220px" }} /> */}
                  <button type="submit" className='bg-black text-white px-10 font-bold  py-4 rounded-lg ' >Send Message</button>

                </SumbitWrapper>
              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" >


              <div className="flex flex-col gap-4 justify-center items-center py-10">
                <h2 style={{ marginTop: "80px" }}><b>OFFICE</b></h2>
                <h5>Aakriti Sadan,Opposite Plam Bellagio,Khamardih,</h5>
                <h5>Shankar Nagar, Raipur (C.G)</h5>
                <h5>Mobile : +(91) 88153 07461 , +(91) 8420887945</h5>
                <br />

                <div className="grid gap-2 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 ">

                  <Link
                    to="https://twitter.com/i/flow/login?redirect_after_login=%2FAPSH_Automation"
                    target="_blank"
                    className="cursor-pointer"
                  >
                    <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                      {" "}
                      <span className="text-blue-400 bg-white rounded-full">
                        <AiFillTwitterCircle />
                      </span>
                      Twitter
                    </span>
                  </Link>

                  <Link
                    to="https://www.facebook.com/people/APSH-Automation/61557750762817/"
                    target="_blank"
                    className="cursor-pointer"
                  >
                    <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                      {" "}
                      <span className="text-blue-500 bg-white rounded-full">
                        <BsFacebook />
                      </span>{" "}
                      Facbook
                    </span>
                  </Link>


                  <Link
                    to="https://www.youtube.com/@APSH_Automation"
                    target="_blank"
                    className="cursor-pointer"
                  >
                    <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                      {" "}
                      <span className="text-white bg-red-500 ">
                        <BsYoutube />
                      </span>{" "}
                      YouTube
                    </span>
                  </Link>


                  <Link
                    to="https://www.instagram.com/apsh_automation/"
                    target="_blank"
                    className="cursor-pointer"
                  >
                    <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                      {" "}
                      <span className="text-red-700 bg-white rounded-full">
                        <BsInstagram />
                      </span>
                      Instagram
                    </span>
                  </Link>

                  {/* <Link
                    to="https://www.linkedin.com/in/yashvitech-it-solution-b10b1a282/"
                    target="_blank"
                    className="cursor-pointer"
                  >
                    <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                      {" "}
                      <span className="text-blue-700 bg-white rounded-full">
                        <BsLinkedin />
                      </span>{" "}
                      LinkedIn
                    </span>
                  </Link> */}

                  {/* <Link
                    // to="https://t.me/"
                    to="/contact"
                    // target="_blank"
                    className="cursor-pointer"
                    onClick={() => window.scroll(0, 0)}
                  >
                    <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                      {" "}
                      <span className="text-blue-700 bg-white rounded-full"></span>
                      <BsTelegram /> Telegram
                    </span>
                  </Link> */}

                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









